@keyframes slide-in-left {
  0% {
    transform: translateX(-200px);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}
@keyframes fade-in-right {
  0% {
    transform: translateX(50px);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}
.frameChild {
  position: relative;
  width: 2.64rem;
  height: 2.65rem;
}
.telcometricsIcon {
  position: relative;
  border-radius: var(--br-12xs);
  width: 8.64rem;
  height: 0.83rem;
}
.groupParent {
  align-self: stretch;
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-smi);
}
.vectorIcon {
  position: relative;
  width: 1.5rem;
  height: 1.25rem;
}
.vectorWrapper {
  cursor: pointer;
  border: 0;
  padding: 0;
  background-color: transparent;
  width: 1.5rem;
  height: 1.25rem;
  display: none;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.aboutUs,
.home {
  text-decoration: none;
  position: relative;
  line-height: 1.14rem;
  font-weight: 500;
  text-shadow: 0 0 0 rgba(255, 255, 255, 0.01);
}
.home {
  color: var(--green-basic);
}
.aboutUs {
  color: inherit;
  cursor: pointer;
}
.aboutUs:active,
.aboutUs:hover {
  color: var(--green-basic);
}
.homeParent,
.ulmenuMenu1 {
  display: flex;
  flex-direction: row;
}
.homeParent {
  width: 15.94rem;
  align-items: center;
  justify-content: space-between;
}
.ulmenuMenu1 {
  padding: var(--padding-13xl) var(--padding-10xl);
  align-items: flex-start;
  justify-content: flex-start;
}
.contactUs {
  flex: 1;
  position: relative;
  font-size: var(--font-size-sm);
  letter-spacing: 1.3px;
  line-height: 1.5rem;
  text-transform: uppercase;
  font-weight: 600;
  font-family: var(--font-inter);
  color: var(--color-gray-100);
  text-align: center;
}
.aelementorButtonLink {
  cursor: pointer;
  border: 0;
  padding: var(--padding-mini);
  background-color: var(--green-basic);
  border-radius: var(--br-8xs);
  box-shadow: 0 0 0 4px rgba(255, 255, 255, 0.01);
  width: 10.3rem;
  display: none;
  flex-direction: row;
  box-sizing: border-box;
  align-items: flex-start;
  justify-content: flex-start;
  transition: all 0.5s ease-out;
}
.aelementorButtonLink:hover {
  background-color: var(--color-turquoise);
  transform: scale(1.2, 1.2);
}
.divelementorContainer,
.frameGroup,
.frameParent {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.frameGroup {
  justify-content: flex-end;
  gap: var(--gap-base);
}
.divelementorContainer,
.frameParent {
  justify-content: center;
}
.frameParent {
  flex: 1;
}
.divelementorContainer {
  width: 75rem;
  height: 5.25rem;
  padding: 0 var(--padding-mid);
  box-sizing: border-box;
  max-width: 75rem;
}
.vectorIcon1 {
  position: relative;
  width: 1rem;
  height: 1rem;
}
.vectorContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.aboutUs1,
.contact1,
.home1 {
  position: relative;
  line-height: 1.14rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.home1 {
  color: var(--green-basic);
  width: 12.13rem;
}
.aboutUs1,
.contact1 {
  font-weight: 500;
  width: 19.13rem;
}
.contact1 {
  width: 16.5rem;
}
.frameContainer,
.homeGroup {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-81xl);
}
.frameContainer {
  gap: var(--gap-31xl);
}
.contactUs1 {
  position: relative;
  letter-spacing: 1.3px;
  line-height: 1.5rem;
  text-transform: uppercase;
  font-weight: 600;
}
.aelementorButtonLink1,
.menuMobile {
  display: none;
  justify-content: flex-start;
}
.aelementorButtonLink1 {
  border-radius: var(--br-8xs);
  background-color: var(--green-basic);
  flex-direction: row;
  padding: var(--padding-mini) 1.92rem var(--padding-mini) var(--padding-11xl);
  align-items: flex-start;
  font-size: var(--font-size-sm);
  color: var(--color-gray-100);
}
.menuMobile {
  background-color: var(--color-gray-400);
  flex-direction: column;
  padding: var(--padding-31xl) var(--padding-39xl);
  align-items: center;
  gap: var(--gap-284xl);
  text-align: center;
  font-size: var(--font-size-13xl);
}
.divelementorBackgroundOverl {
  position: relative;
  background: linear-gradient(180deg, rgba(0, 4, 44, 0.84) 15%, #00142c);
  width: 120rem;
  height: 57.69rem;
  display: none;
}
.developmentAndCompliance {
  color: var(--font-white-basic);
}
.telecomSoftwareDevelopmentContainer {
  margin: 0;
  align-self: stretch;
  position: relative;
  font-size: inherit;
  line-height: 4.19rem;
  font-weight: 700;
  font-family: inherit;
  opacity: 0;
}
.telecomSoftwareDevelopmentContainer.animate {
  animation: 1s ease 0.15s 1 normal forwards slide-in-left;
}
.securitypngIcon {
  align-self: stretch;
  flex: 1;
  position: relative;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
  object-fit: cover;
  background-size: contain;
}
.securitypngWrapper {
  align-self: stretch;
  display: none;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  opacity: 0;
  max-width: 37.5rem;
  max-height: 31.25rem;
  margin: 0 auto;
}
.divelementorWidgetContainer.animate,
.securitypngWrapper.animate {
  animation: 1s ease 0.25s 1 normal forwards slide-in-left;
}
.atTelcometricsOur {
  flex: 1;
  position: relative;
  line-height: 1.5rem;
}
.divelementorWidgetContainer {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  opacity: 0;
  font-size: var(--font-size-base);
  color: var(--color-lightslategray);
  font-family: var(--font-heebo);
}
.learnMore {
  position: relative;
  font-size: var(--font-size-base);
  letter-spacing: 1.3px;
  line-height: 1.5rem;
  text-transform: uppercase;
  font-weight: 600;
  font-family: var(--font-inter);
  color: var(--font-white-basic);
  text-align: center;
}
.aelementorButtonLink2 {
  cursor: pointer;
  border: 0;
  padding: var(--padding-xl) 2.54rem var(--padding-xl) var(--padding-21xl);
  background-color: var(--green-basic);
  border-radius: var(--br-8xs);
  box-shadow: 0 0 0 4px rgba(255, 255, 255, 0.01);
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  transition: all 0.5s ease-out;
}
.aelementorButtonLink2:hover {
  background-color: var(--color-turquoise);
  transform: scale(1.2, 1.2);
}
.aelementorButtonLinkWrapper {
  opacity: 0;
}
.aelementorButtonLinkWrapper.animate {
  animation: 1s ease 0.35s 1 normal forwards slide-in-left;
}
.aelementorButtonLinkWrapper,
.divelementorWidgetWrap,
.divelementorWidgetWrap1 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.divelementorWidgetWrap1 {
  align-self: stretch;
  gap: var(--gap-6xl);
}
.divelementorWidgetWrap {
  flex: 1;
}
.securitypngIcon1 {
  position: relative;
  width: 40.38rem;
  height: 29.06rem;
  object-fit: cover;
}
.securitypngContainer {
  flex-direction: column;
  opacity: 0;
}
.securitypngContainer.animate {
  animation: 1s ease 0.15s 1 normal forwards fade-in-right;
}
.divelementorWidgetWrapParent,
.frameWrapper,
.securitypngContainer {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.divelementorWidgetWrapParent {
  align-self: stretch;
  flex-direction: row;
  gap: var(--gap-3xs);
}
.frameWrapper {
  width: 75rem;
  flex-direction: column;
  padding: var(--padding-141xl) var(--padding-21xl) var(--padding-11xl);
  box-sizing: border-box;
  max-width: 75rem;
  font-size: var(--font-size-33xl);
  color: var(--green-basic);
}
.divelementorContainerParent,
.sectionelementorSection {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}
.divelementorContainerParent {
  background: linear-gradient(
    180deg,
    rgba(0, 20, 44, 0.96) 15%,
    rgba(0, 20, 44, 0.98)
  );
  gap: var(--gap-11xl);
}
.sectionelementorSection {
  background-image: url(/public/sectionelementorsection@3x.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
  text-align: left;
  font-size: var(--font-size-sm);
  color: var(--font-white-basic);
  font-family: var(--font-inter);
}
@media screen and (max-width: 1200px) {
  .vectorWrapper {
    display: none;
  }
  .aelementorButtonLink,
  .frameGroup,
  .ulmenuMenu1 {
    display: flex;
  }
  .frameGroup {
    flex: 1;
  }
  .divelementorContainer {
    align-self: stretch;
    width: auto;
    align-items: center;
    justify-content: center;
  }
  .menuMobile {
    display: none;
    width: auto;
  }
  .telecomSoftwareDevelopmentContainer {
    font-size: var(--font-size-33xl);
    line-height: 4.19rem;
  }
  .securitypngIcon,
  .securitypngWrapper {
    display: flex;
    align-self: stretch;
    width: auto;
  }
  .securitypngIcon {
    flex: 1;
  }
  .securitypngWrapper {
    height: auto;
    max-width: 37.5rem;
  }
  .divelementorWidgetContainer {
    display: flex;
  }
  .divelementorWidgetWrap1 {
    align-items: flex-start;
    justify-content: flex-start;
  }
  .divelementorWidgetWrap {
    flex: 1;
    height: auto;
  }
  .securitypngIcon1 {
    display: flex;
  }
  .securitypngContainer {
    display: none;
  }
  .frameWrapper {
    align-self: stretch;
    width: auto;
    padding-top: var(--padding-61xl);
    box-sizing: border-box;
  }
}
@media screen and (max-width: 960px) {
  .vectorWrapper {
    display: flex;
  }
  .aelementorButtonLink,
  .ulmenuMenu1 {
    display: none;
  }
  .frameGroup {
    display: flex;
  }
  .divelementorContainer {
    align-self: stretch;
    width: auto;
    align-items: center;
    justify-content: center;
  }
  .menuMobile {
    display: none;
  }
  .telecomSoftwareDevelopmentContainer {
    font-size: var(--font-size-13xl);
    line-height: 2.94rem;
    text-align: center;
  }
  .securitypngIcon,
  .securitypngWrapper {
    display: flex;
    align-self: stretch;
    width: auto;
  }
  .securitypngWrapper {
    height: auto;
    max-width: 31.25rem;
  }
  .divelementorWidgetContainer {
    display: none;
  }
  .divelementorWidgetWrap1 {
    align-items: center;
    justify-content: flex-start;
  }
  .divelementorWidgetWrap {
    flex: 1;
    height: auto;
  }
  .securitypngContainer,
  .securitypngIcon1 {
    display: none;
  }
  .frameWrapper {
    padding-top: var(--padding-21xl);
    box-sizing: border-box;
  }
}
@media screen and (max-width: 420px) {
  .vectorWrapper {
    display: flex;
  }
  .aelementorButtonLink,
  .ulmenuMenu1 {
    display: none;
  }
  .frameGroup {
    display: flex;
  }
  .divelementorContainer {
    align-self: stretch;
    width: auto;
    align-items: center;
    justify-content: center;
  }
  .menuMobile {
    display: none;
    align-self: stretch;
    width: auto;
  }
  .telecomSoftwareDevelopmentContainer {
    font-size: var(--font-size-13xl);
    line-height: 2.94rem;
    text-align: center;
  }
  .securitypngIcon,
  .securitypngWrapper {
    display: flex;
    align-self: stretch;
    width: auto;
  }
  .securitypngWrapper {
    height: auto;
    max-width: 25rem;
  }
  .divelementorWidgetContainer {
    display: none;
  }
  .divelementorWidgetWrap1 {
    align-items: center;
    justify-content: flex-start;
  }
  .divelementorWidgetWrap {
    flex: 1;
    height: auto;
  }
  .securitypngContainer,
  .securitypngIcon1 {
    display: none;
  }
}
