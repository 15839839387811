@keyframes scale-up {
  0% {
    transform: scale(0.5);
    opacity: 0;
  }
  to {
    transform: scale(1);
    opacity: 1;
  }
}
.databasepngIcon {
  display: none;
}
.databasepngIcon,
.databasepngIcon1 {
  position: relative;
  width: 3.75rem;
  height: 3.75rem;
  object-fit: cover;
}
.consulting {
  position: relative;
  line-height: 1.95rem;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 13.35rem;
  height: 1.81rem;
  flex-shrink: 0;
}
.p {
  text-transform: uppercase;
}
.rofitFromOur {
  text-transform: lowercase;
}
.profitFromOurContainer {
  flex: 1;
  position: relative;
  line-height: 1.5rem;
}
.piconBoxDescription {
  width: 17.13rem;
  display: flex;
  flex-direction: row;
  padding: 0 var(--padding-6xs);
  box-sizing: border-box;
  align-items: flex-start;
  justify-content: flex-start;
  font-size: var(--font-size-base);
  color: var(--color-lightslategray);
  font-family: var(--font-heebo);
}
.consultingParent {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-2xs);
}
.div,
.div1,
.readMore {
  position: relative;
  letter-spacing: 1.3px;
  text-transform: uppercase;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
}
.div {
  line-height: 1.5rem;
  font-weight: 600;
  display: flex;
  width: 5.17rem;
  height: 0.94rem;
}
.div1,
.readMore {
  display: none;
}
.readMore {
  line-height: 1.5rem;
  font-weight: 600;
  width: 5.17rem;
  height: 0.94rem;
}
.div1 {
  line-height: 0.75rem;
  font-family: var(--font-font-awesome-5-free);
  width: 0.75rem;
  height: 0.81rem;
}
.parent {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-3xs);
  font-size: var(--font-size-xs);
  color: var(--green-basic);
}
.diviconBox {
  padding: var(--padding-12xs) 0;
  align-items: center;
  gap: var(--gap-5xl);
}
.diviconBox,
.divjkitIconBoxWrapper,
.divjkitIconBoxWrapperWrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.divjkitIconBoxWrapper {
  align-self: stretch;
  border-radius: var(--br-3xs);
  background-color: var(--color-gray-300);
  box-shadow: 0 0 0 4px rgba(255, 255, 255, 0.01);
  border: 1px solid var(--color-turquoise-100);
  padding: var(--padding-21xl);
  align-items: center;
  gap: var(--gap-5xl);
  transition: all 0.3s ease-out;
}
.divjkitIconBoxWrapper:hover {
  background-color: var(--color-gray-300);
  border: 1px solid var(--green-basic)!important;
  box-sizing: border-box;
  transform: scale(1.1, 1.1);
}
.divjkitIconBoxWrapperWrapper {
  flex: 1;
  align-items: flex-start;
  opacity: 0;
  text-align: center;
  font-size: var(--font-size-5xl);
  color: var(--font-white-basic);
  font-family: var(--font-inter);
}
.divjkitIconBoxWrapperWrapper.animate {
  animation: 1s ease 0.15s 1 normal forwards scale-up;
}
@media screen and (max-width: 1200px) {
  .divjkitIconBoxWrapper {
    align-self: stretch;
    width: auto;
    height: auto;
  }
  .divjkitIconBoxWrapperWrapper {
    flex: unset;
    height: auto;
    align-self: stretch;
  }
}
@media screen and (max-width: 960px) {
  .divjkitIconBoxWrapper {
    align-self: stretch;
    width: auto;
    height: auto;
  }
  .divjkitIconBoxWrapperWrapper {
    flex: unset;
    height: auto;
    align-self: stretch;
  }
}
@media screen and (max-width: 420px) {
  .divjkitIconBoxWrapper {
    align-self: stretch;
    width: auto;
    height: auto;
  }
  .divjkitIconBoxWrapperWrapper {
    flex: unset;
    height: auto;
    align-self: stretch;
  }
  .divjkitIconBoxWrapper:hover {
    transform: scale(1.0, 1.0);
  }
}
