@import url("https://fonts.googleapis.com/css2?family=Inter:wght@500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Heebo:wght@400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Font+Awesome+5+Free:wght@400&display=swap");
body {
  margin: 0;
  line-height: normal;
  transition: all 0.4s ease-out!important;
  background-color: #00142C !important;
}
:root {
  /* fonts */
  --font-inter: Inter;
  --font-heebo: Heebo;
  --font-font-awesome-5-free: "Font Awesome 5 Free";
  --font-inherit: inherit;

  /* font sizes */
  --font-size-sm: 0.88rem;
  --font-size-base: 1rem;
  --font-size-17xl: 2.25rem;
  --font-size-13xl: 2rem;
  --font-size-5xl: 1.5rem;
  --font-size-lg: 1.13rem;
  --font-size-xs: 0.75rem;
  --font-size-3xl: 1.38rem;
  --font-size-xl: 1.25rem;
  --font-size-33xl: 3.25rem;

  /* Colors */
  --color-gray-100: #f9feff;
  --color-gray-200: #061f3e;
  --color-gray-300: #011936;
  --color-gray-400: #00142c;
  --color-gray-500: rgba(255, 255, 255, 0.09);
  --font-white-basic: #fff;
  --green-basic: #0ec9ac;
  --color-mediumaquamarine-200: rgba(14, 201, 172, 0);
  --color-whitesmoke-100: #f4f4f4;
  --color-whitesmoke-200: #eee;
  --color-lightslategray: #828fa0;
  --color-turquoise: #00e2bf;
  --color-turquoise-100: rgba(14, 201, 173, 0);

  /* Gaps */
  --gap-3xs: 0.63rem;
  --gap-6xl: 1.56rem;
  --gap-21xl: 2.5rem;
  --gap-31xl: 3.13rem;
  --gap-7xl: 1.63rem;
  --gap-81xl: 6.25rem;
  --gap-mini: 0.94rem;
  --gap-5xl: 1.5rem;
  --gap-11xl: 1.88rem;
  --gap-2xs: 0.69rem;
  --gap-xl: 1.25rem;
  --gap-xs: 0.75rem;
  --gap-base: 1rem;
  --gap-lg: 1.13rem;
  --gap-mid: 1.06rem;
  --gap-9xl: 1.75rem;
  --gap-284xl: 18.94rem;
  --gap-smi: 0.81rem;

  /* Paddings */
  --padding-81xl: 6.25rem;
  --padding-21xl: 2.5rem;
  --padding-11xl: 1.88rem;
  --padding-6xl: 1.56rem;
  --padding-xl: 1.25rem;
  --padding-71xl: 5.63rem;
  --padding-13xl: 2rem;
  --padding-31xl: 3.13rem;
  --padding-3xs: 0.63rem;
  --padding-mini: 0.94rem;
  --padding-lg: 1.13rem;
  --padding-5xl: 1.5rem;
  --padding-8xs: 0.31rem;
  --padding-12xs: 0.06rem;
  --padding-6xs: 0.44rem;
  --padding-14xl: 2.06rem;
  --padding-2xl: 1.31rem;
  --padding-19xl: 2.38rem;
  --padding-16xl: 2.19rem;
  --padding-141xl: 10rem;
  --padding-61xl: 5rem;
  --padding-21xl-6: 2.54rem;
  --padding-39xl: 3.63rem;
  --padding-11xl-8: 1.93rem;
  --padding-mid: 1.06rem;
  --padding-10xl: 1.81rem;

  /* border radiuses */
  --br-3xs: 10px;
  --br-8xs: 5px;
  --br-5xs-5: 7.5px;
  --br-12xs: 1px;
}




#cardOpacityOne > div > div {
  transition: all 0.4s ease-out!important;
}
#cardOpacityOne:hover > div > div {
  opacity: 0.3!important;
  transition: all 0.4s ease-out!important;
}
#cardOpacityOne:hover > div > div:hover {
  opacity: 1!important;
}


#cardOpacitySec > div {
  transition: all 0.4s ease-out!important;
}
#cardOpacitySec:hover > div {
  opacity: 0.3!important;
  transition: all 0.4s ease-out!important;
}
#cardOpacitySec:hover > div:hover {
  opacity: 1!important;
}


.swiper-pagination-bullet-active {
  background-color: #0ec9ac !important;
}
.swiper-pagination-bullet {
  background-color: #0ec9ac !important;
}


.swiper {
  width: 100%;
  /*height: 100%;*/
  /*background-color: #272727;*/
}

.swiper-slide {
/*width: 90%;*/

/* Center slide text vertically */
  /*display: flex;
  justify-content: center;
  align-items: center;*/

  /*align-self: stretch;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 0 var(--padding-11xl);
  box-sizing: border-box;
  justify-content: flex-start;
  gap: var(--gap-81xl);
  max-width: 75rem;
  text-align: center;
  font-size: var(--font-size-13xl);
  color: var(--color-white);
  font-family: var(--font-inter);*/

  /*background-color: #272727;*/
}

.swiper-slide img {
  /*display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;*/
}


.swiper-nav-btns {
  margin-bottom: 30px!important;
}


@media screen and (max-width: 1200px) {

  .swiper-slide {
    width: 90%;
  }

}

@media screen and (max-width: 960px) {

  .swiper-slide {
    width: 90%;
  }

}

@media screen and (max-width: 420px) {

  .swiper-slide {
    width: 90%;
  }

}