@keyframes slide-in-left {
  0% {
    transform: translateX(-200px);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}
.frameIcon {
  position: absolute;
  top: 3.06rem;
  left: 6.63rem;
  width: 3rem;
  height: 3.13rem;
  overflow: hidden;
}
.div,
.div1 {
  position: absolute;
  font-weight: 600;
}
.div {
  top: 0;
  left: 4.76rem;
  line-height: 3rem;
}
.div1 {
  top: 0.65rem;
  left: 10.76rem;
  font-size: var(--font-size-3xl);
  color: var(--green-basic);
}
.divnumberWrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 16.25rem;
  height: 3rem;
}
.cybersecurityProjects {
  position: absolute;
  top: 3rem;
  left: 3.08rem;
  font-size: var(--font-size-base);
  line-height: 1.5rem;
  font-family: var(--font-heebo);
  color: var(--color-lightslategray);
}
.divcontent {
  position: absolute;
  top: 3rem;
  left: 0;
  width: 16.25rem;
  height: 4.5rem;
}
.divfunFactInner {
  position: relative;
  width: 16.25rem;
  height: 7.5rem;
}
.divjegElementorKit {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  padding: var(--padding-3xs);
  align-items: flex-start;
  justify-content: flex-start;
  opacity: 0;
  text-align: center;
  font-size: var(--font-size-13xl);
  color: var(--font-white-basic);
  font-family: var(--font-inter);
}
.divjegElementorKit.animate {
  animation: 1s ease 0.15s 1 normal forwards slide-in-left;
}
