.cloud150x150pngIcon {
  position: relative;
  width: 2.5rem;
  height: 2.5rem;
  object-fit: cover;
}
.highQualityService {
  align-self: stretch;
  position: relative;
  line-height: 1.95rem;
  font-weight: 600;
  display: flex;
  align-items: center;
  height: 1.81rem;
  flex-shrink: 0;
}
.loremIpsumDolor {
  flex: 1;
  position: relative;
  line-height: 1.5rem;
}
.diviconBox,
.divjkitIconBoxWrapper,
.piconBoxDescription {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.piconBoxDescription {
  align-self: stretch;
  flex-direction: row;
  font-size: var(--font-size-base);
  color: var(--color-lightslategray);
  font-family: var(--font-heebo);
}
.diviconBox,
.divjkitIconBoxWrapper {
  flex-direction: column;
}
.diviconBox {
  align-self: stretch;
  gap: var(--gap-mid);
}
.divjkitIconBoxWrapper {
  border-radius: var(--br-8xs);
  width: 16.09rem;
  overflow: hidden;
  gap: var(--gap-lg);
  transition: all 0.5s ease-out;
  text-align: left;
  font-size: var(--font-size-5xl);
  color: var(--font-white-basic);
  font-family: var(--font-inter);
  margin-left: 15px;
}
.divjkitIconBoxWrapper:hover {
  transform: scale(1.05, 1.05);
}
@media screen and (max-width: 1200px) {
  .divjkitIconBoxWrapper {
    width: auto;
    height: auto;
  }
}
@media screen and (max-width: 960px) {
  .divjkitIconBoxWrapper {
    width: auto;
  }
}
@media screen and (max-width: 420px) {
  .divjkitIconBoxWrapper {
    align-self: stretch;
    width: auto;
  }
}
