@keyframes slide-in-bottom {
  0% {
    transform: translateY(200px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}
@keyframes scale-up {
  0% {
    transform: scale(0.5);
    opacity: 0;
  }
  to {
    transform: scale(1);
    opacity: 1;
  }
}
@keyframes slide-in-left {
  0% {
    transform: translateX(-200px);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}
@keyframes slide-in-right {
  0% {
    transform: translateX(200px);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}
@keyframes fade-in-bottom {
  0% {
    transform: translateY(50px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}
.groupChild {
  position: absolute;
  top: 2.66rem;
  left: 0;
  border-radius: 50%;
  background-color: #1d1d1c;
  width: 3.07rem;
  height: 4.9rem;
  display: none;
}
.image4Icon {
  position: absolute;
  top: 0;
  left: 0;
  width: 2rem;
  height: 3.36rem;
  object-fit: cover;
}
.ellipseParent {
  position: relative;
  width: 2rem;
  height: 3.36rem;
}
.divpageInner {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  opacity: 0;
}
.divpageInner.animate {
  animation: 1s ease 0.15s 1 normal forwards slide-in-bottom;
}
.divelementorContainerChild {
  position: absolute;
  top: 0;
  left: -4.69rem;
  height: 15.88rem;
}
.divelementorBackgroundOverl,
.divelementorContainer {
  position: relative;
  width: 69.94rem;
  height: 15.88rem;
  display: none;
}
.divelementorBackgroundOverl {
  background: linear-gradient(
    180deg,
    rgba(0, 20, 44, 0.96) 49%,
    rgba(0, 20, 44, 0.98),
    rgba(0, 20, 44, 0.98)
  );
  width: 50rem;
  height: 73.94rem;
}
.weAnalyseOur {
  margin: 0;
}
.weAnalyseOurContainer {
  margin: 0;
  position: relative;
  font-size: inherit;
  line-height: 2.88rem;
  font-weight: 600;
  font-family: inherit;
  text-shadow: 0 0 0 rgba(255, 255, 255, 0.01);
}
.divjkitIconBoxWrapperContainer,
.divjkitIconBoxWrapperWrapper {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  opacity: 0;
}
.divjkitIconBoxWrapperWrapper.animate {
  animation: 1s ease 0.15s 1 normal forwards scale-up;
}
.divjkitIconBoxWrapperContainer.animate {
  animation: 1s ease 0.35s 1 normal forwards scale-up;
}
.frameGroup {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  gap: var(--gap-5xl);
  transition: all 0.5s ease-out;
}
.divjkitIconBoxWrapperFrame,
.frameDiv {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  opacity: 0;
}
.divjkitIconBoxWrapperFrame.animate {
  animation: 1s ease 0.55s 1 normal forwards scale-up;
}
.frameDiv.animate {
  animation: 1s ease 0.75s 1 normal forwards scale-up;
}
.frameParent {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-5xl);
  transition: all 0.5s ease-out;
  opacity: 1;
}
.frameParent:empty .divjkitIconBoxWrapper1 {
  transition: all 0.5s ease-out;
  opacity: 1;
}
.needssection,
.welcomeToTelcometrics {
  align-self: stretch;
  display: flex;
  align-items: center;
}
.needssection {
  flex-direction: column;
  padding: 0 var(--padding-11xl);
  box-sizing: border-box;
  justify-content: flex-start;
  gap: var(--gap-81xl);
  max-width: 75rem;
  text-align: center;
  font-size: var(--font-size-13xl);
  color: var(--font-white-basic);
  font-family: var(--font-inter);
}
.welcomeToTelcometrics {
  position: relative;
  line-height: 1.13rem;
  font-weight: 500;
  height: 1.31rem;
  flex-shrink: 0;
  opacity: 0;
}
.welcomeToTelcometrics.animate {
  animation: 1s ease 0.15s 1 normal forwards slide-in-left;
}
.telecomSoftwareDevelopment {
  align-self: stretch;
  position: relative;
  font-size: var(--font-size-17xl);
  line-height: 2.93rem;
  font-weight: 600;
  color: var(--font-white-basic);
  text-shadow: 0 0 0 rgba(255, 255, 255, 0.01);
  opacity: 0;
}
.telecomSoftwareDevelopment.animate {
  animation: 1s ease 0.25s 1 normal forwards slide-in-left;
}
.welcomeToTelcometricsParent {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-xl);
}
.image1Icon {
  align-self: stretch;
  flex: 1;
  position: relative;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
  object-fit: cover;
}
.divelementorBackgroundOverl1 {
  align-self: stretch;
  border-radius: 0 var(--br-3xs) var(--br-3xs) 0;
  height: 30.56rem;
  display: none;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  opacity: 0;
  max-width: 31.25rem;
  max-height: 31.25rem;
}
.divelementorBackgroundOverl1.animate {
  animation: 1s ease 0.35s 1 normal forwards slide-in-left;
}
.ourTeamOfContainer {
  align-self: stretch;
  position: relative;
  font-size: var(--font-size-base);
  line-height: 1.5rem;
  font-family: var(--font-heebo);
  color: var(--color-lightslategray);
  opacity: 0;
}
.ourTeamOfContainer.animate {
  animation: 1s ease 0.45s 1 normal forwards slide-in-left;
}
.divjkitIconBoxWrapperWrapper1,
.divjkitIconBoxWrapperWrapper2,
.frameParent4 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.frameParent4 {
  align-self: stretch;
  gap: var(--gap-11xl);
}
.divjkitIconBoxWrapperWrapper1,
.divjkitIconBoxWrapperWrapper2 {
  opacity: 0;
}
.divjkitIconBoxWrapperWrapper1.animate {
  animation: 1s ease 0.55s 1 normal forwards scale-up;
}
.divjkitIconBoxWrapperWrapper2.animate {
  animation: 1s ease 0.75s 1 normal forwards scale-up;
}
.divelementorContainer1,
.frameParent5 {
  align-self: stretch;
  display: flex;
  align-items: flex-start;
}
.frameParent5 {
  flex-direction: row;
  justify-content: center;
  gap: var(--gap-21xl);
}
.divelementorContainer1 {
  flex-direction: column;
  justify-content: flex-start;
}
.readMore {
  position: relative;
  font-size: var(--font-size-sm);
  letter-spacing: 1.3px;
  line-height: 1.5rem;
  text-transform: uppercase;
  font-weight: 600;
  font-family: var(--font-inter);
  color: var(--font-white-basic);
  text-align: center;
}
.aelementorButton,
.aelementorButtonWrapper,
.frameParent3 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.aelementorButton {
  cursor: pointer;
  border: 0;
  padding: var(--padding-lg) var(--padding-11xl) var(--padding-lg)
    var(--padding-14xl);
  background-color: var(--green-basic);
  border-radius: var(--br-8xs);
  box-shadow: 0 0 0 4px rgba(255, 255, 255, 0.01);
  flex-direction: row;
  transition: all 0.5s ease-out;
}
.aelementorButton:hover {
  background-color: var(--color-turquoise);
  transform: scale(1.2, 1.2);
}
.aelementorButtonWrapper,
.frameParent3 {
  flex-direction: column;
}
.aelementorButtonWrapper {
  opacity: 0;
}
.aelementorButtonWrapper.animate {
  animation: 1s ease 0.75s 1 normal forwards slide-in-left;
}
.frameParent3 {
  flex: 1;
  gap: var(--gap-11xl);
}
.image1Icon1 {
  align-self: stretch;
  position: relative;
  max-width: 100%;
  overflow: hidden;
  height: 30.56rem;
  flex-shrink: 0;
  object-fit: cover;
}
.divelementorBackgroundOverl2 {
  flex: 1;
  border-radius: 0 var(--br-3xs) var(--br-3xs) 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  opacity: 0;
}
.divelementorBackgroundOverl2.animate {
  animation: 1s ease 0.15s 1 normal forwards slide-in-right;
}
.frameParent2 {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-11xl);
}
.companyHistory,
.groupIcon {
  align-self: stretch;
  position: relative;
}
.companyHistory {
  font-size: var(--font-size-17xl);
  line-height: 2.93rem;
  font-weight: 600;
  color: var(--font-white-basic);
  opacity: 0;
}
.companyHistory.animate {
  animation: 1s ease 0.25s 1 normal forwards slide-in-left;
}
.groupIcon {
  max-width: 100%;
  overflow: hidden;
  height: 39.41rem;
  flex-shrink: 0;
}
.frame {
  align-self: stretch;
  height: 31.25rem;
  display: none;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.image5Icon {
  align-self: stretch;
  position: relative;
  max-width: 100%;
  overflow: hidden;
  height: 33.66rem;
  flex-shrink: 0;
  object-fit: cover;
}
.divelementorBackgroundOverl3 {
  align-self: stretch;
  border-radius: 0 var(--br-5xs-5) var(--br-5xs-5) 0;
  display: none;
  flex-direction: column;
  padding: var(--padding-12xs) 0;
  box-sizing: border-box;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-3xs);
  opacity: 0;
  max-width: 31.25rem;
  max-height: 31.25rem;
}
.divelementorBackgroundOverl3.animate,
.telcometricsWasEstablishedContainer.animate {
  animation: 1s ease 0.35s 1 normal forwards slide-in-left;
}
.telcometricsWasEstablished {
  margin: 0;
  color: var(--font-white-basic);
}
.theIdentificationAndDevelop {
  margin-bottom: false;
  margin-top: 1.35rem;
}
.theDeploymentOfATeamOfEx {
  margin: 0;
  padding-left: var(--padding-2xl);
}
.telcometricsWasEstablishedContainer {
  align-self: stretch;
  position: relative;
  font-size: var(--font-inherit);
  line-height: 1.5rem;
  font-family: var(--font-inherit);
  opacity: 0;
  color: var(--color-lightslategray);
}
.vectorIcon {
  position: relative;
  width: 1.37rem;
  height: 1.33rem;
}
.aelementorButton1,
.vectorParent {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}
.vectorParent {
  gap: var(--gap-base);
}
.aelementorButton1 {
  cursor: pointer;
  border: 0;
  padding: var(--padding-lg) 2rem var(--padding-lg) var(--padding-14xl);
  background-color: var(--green-basic);
  border-radius: var(--br-8xs);
  transition: all 0.5s ease-out;
}
.aelementorButton1:hover {
  transform: scale(1.2, 1.2);
}
.aelementorButtonContainer,
.divelementorWidgetContainerWrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.aelementorButtonContainer {
  opacity: 0;
}
.aelementorButtonContainer.animate {
  animation: 1s ease 0.45s 1 normal forwards slide-in-left;
}
.divelementorWidgetContainerWrapper {
  flex: 1;
}
.frameIcon {
  position: relative;
  width: 39.14rem;
  height: 33.66rem;
  overflow: hidden;
  flex-shrink: 0;
  display: none;
}
.divelementorBackgroundOverl4 {
  flex: 1;
  border-radius: 0 var(--br-5xs-5) var(--br-5xs-5) 0;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  padding: var(--padding-12xs) 0;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-3xs);
  opacity: 0;
}
.divelementorBackgroundOverl4.animate {
  animation: 1s ease 0.15s 1 normal forwards slide-in-right;
}
.frameParent6 {
  align-self: stretch;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: var(--gap-11xl);
}
.frameParent1,
.welcomesection {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.frameParent1 {
  align-items: flex-start;
  gap: var(--gap-3xs);
}
.welcomesection {
  padding: 0 var(--padding-11xl);
  box-sizing: border-box;
  align-items: center;
  max-width: 75rem;
  text-align: left;
  font-size: var(--font-size-lg);
  color: var(--green-basic);
  font-family: var(--font-inter);
}
.frameIcon1 {
  position: absolute;
  top: 3.06rem;
  left: 6.63rem;
  width: 3rem;
  height: 3.13rem;
  overflow: hidden;
}
.div,
.div1 {
  position: absolute;
  font-weight: 600;
}
.div {
  top: 0;
  left: 4.92rem;
  line-height: 3rem;
}
.div1 {
  top: 0.65rem;
  left: 10.59rem;
  font-size: var(--font-size-3xl);
  color: var(--green-basic);
}
.divnumberWrapper {
  position: relative;
  width: 16.25rem;
  height: 3rem;
}
.clientsProtection {
  position: relative;
  font-size: var(--font-size-base);
  line-height: 1.5rem;
  font-family: var(--font-heebo);
  color: var(--color-lightslategray);
}
.divcontent {
  position: absolute;
  top: 3rem;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}
.divfunFactInner {
  position: relative;
  width: 16.25rem;
  height: 7.5rem;
}
.divjegElementorKit {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  padding: var(--padding-3xs);
  align-items: flex-start;
  justify-content: flex-start;
  opacity: 0;
}
.divjegElementorKit.animate {
  animation: 1s ease 0.25s 1 normal forwards slide-in-bottom;
}
.divjegElementorKitParent {
  display: flex;
  flex-direction: row;
  padding: var(--padding-11xl) 0;
  align-items: center;
  justify-content: center;
  gap: var(--gap-mini);
}
.divelementorContainer2 {
  display: flex;
}
.counterssection,
.divelementorContainer2,
.sectionelementorSection {
  align-self: stretch;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}
.sectionelementorSection {
  border-radius: var(--br-3xs);
  background-color: var(--color-gray-300);
  display: flex;
  padding: var(--padding-xl) var(--padding-11xl) var(--padding-21xl);
}
.counterssection {
  display: none;
  padding: 0 var(--padding-11xl);
  box-sizing: border-box;
  max-width: 75rem;
  text-align: center;
  font-size: var(--font-size-13xl);
  color: var(--font-white-basic);
  font-family: var(--font-inter);
}
.divelementorContainer3 {
  align-self: stretch;
  background-color: var(--color-gray-300);
  display: flex;
  flex-direction: row;
  padding: var(--padding-21xl) 3.44rem;
  align-items: flex-start;
  justify-content: center;
  border-radius: var(--br-3xs);
}
.industriessection {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  padding: 0 var(--padding-11xl);
  box-sizing: border-box;
  align-items: center;
  justify-content: flex-start;
  max-width: 75rem;
}
.ourServices,
.whatWeOffer {
  position: relative;
  line-height: 1.13rem;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 8.25rem;
  height: 1.31rem;
  flex-shrink: 0;
  opacity: 0;
}
.ourServices.animate {
  animation: 1s ease 0s 1 normal forwards fade-in-bottom;
}
.whatWeOffer {
  font-size: var(--font-size-17xl);
  line-height: 2.93rem;
  font-weight: 600;
  color: var(--font-white-basic);
  width: 16.01rem;
  height: 2.75rem;
}
.whatWeOffer.animate {
  animation: 1s ease 0.3s 1 normal forwards fade-in-bottom;
}
.ourServicesParent {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-xl);
}
.divelementorContainer4 {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  gap: var(--gap-11xl);
  transition: all 0.5s ease-out;
}
.frameParent8,
.offersection {
  align-self: stretch;
  display: flex;
  justify-content: flex-start;
}
.offersection {
  flex-direction: column;
  padding: 0 var(--padding-11xl);
  box-sizing: border-box;
  align-items: center;
  gap: 3.88rem;
  max-width: 75rem;
  text-align: center;
  font-size: var(--font-size-lg);
  color: var(--green-basic);
  font-family: var(--font-inter);
}
.frameParent8 {
  flex-direction: row;
  align-items: flex-start;
  gap: var(--gap-21xl);
}
.divelementorContainer5,
.servicessection {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.divelementorContainer5 {
  background-color: transparent;
  justify-content: center;
}
.servicessection {
  padding: var(--padding-lg) var(--padding-11xl);
  box-sizing: border-box;
  justify-content: flex-start;
  max-width: 75rem;
}
.icon {
  position: relative;
  width: 0.94rem;
  height: 1.25rem;
}
.divicon {
  border-radius: 50px;
  background-color: var(--green-basic);
  box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.01);
  display: flex;
  flex-direction: row;
  padding: var(--padding-3xs) 0.75rem;
  align-items: center;
  justify-content: center;
}
.letsUseOur {
  flex: 1;
  position: relative;
  line-height: 1.25rem;
  font-weight: 500;
}
.divjkitIconBoxWrapper,
.letsUseOurServiceWrapper {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}
.letsUseOurServiceWrapper {
  flex: 1;
  align-items: flex-start;
}
.divjkitIconBoxWrapper {
  border-radius: var(--br-8xs);
  width: 31.25rem;
  align-items: center;
  gap: var(--gap-mini);
}
.checkAbilityToContainer {
  flex: 1;
  position: relative;
  line-height: 2.75rem;
  font-weight: 600;
}
.divelementorWidgetWrap,
.h2elementorHeadingTitle {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.h2elementorHeadingTitle {
  width: 31.25rem;
  flex-direction: row;
  font-size: var(--font-size-13xl);
  color: var(--font-white-basic);
}
.divelementorWidgetWrap {
  flex: 1;
  flex-direction: column;
  gap: var(--gap-mini);
}
.email,
.emailWrapper {
  height: 4rem;
  overflow: hidden;
  flex-shrink: 0;
  display: flex;
}
.email {
  border: 0;
  font-family: var(--font-heebo);
  font-size: var(--font-size-base);
  background-color: var(--color-whitesmoke-100);
  align-self: stretch;
  border-radius: var(--br-3xs);
  box-shadow: 0 5px 15px -5px rgba(0, 0, 0, 0.11);
  flex-direction: row;
  padding: var(--padding-8xs) var(--padding-3xs) var(--padding-8xs)
    var(--padding-6xl);
  box-sizing: border-box;
  align-items: center;
  justify-content: flex-end;
}
.emailWrapper {
  width: 25rem;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.check {
  position: absolute;
  top: 0.94rem;
  left: 1.11rem;
  font-size: var(--font-size-sm);
  letter-spacing: 1.3px;
  line-height: 1.5rem;
  text-transform: uppercase;
  font-weight: 600;
  font-family: var(--font-inter);
  color: var(--font-white-basic);
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 5.67rem;
  height: 1.06rem;
}
.submit {
  cursor: pointer;
  border: 0;
  padding: 0;
  background-color: var(--green-basic);
  position: relative;
  border-radius: var(--br-3xs);
  width: 7.88rem;
  height: 3rem;
  display: none;
}
.frameForm,
.frameWrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.frameForm {
  gap: var(--gap-3xs);
}
.frameWrapper {
  width: 25rem;
  height: 4rem;
  max-width: 25rem;
}
.checklocationsection,
.divelementorWidgetWrapParent {
  align-self: stretch;
  align-items: center;
  justify-content: flex-start;
}
.divelementorWidgetWrapParent {
  display: flex;
  flex-direction: row;
  gap: 3.38rem;
}
.checklocationsection {
  display: none;
  flex-direction: column;
  padding: 0 var(--padding-11xl);
  box-sizing: border-box;
  opacity: 0;
  max-width: 75rem;
  text-align: left;
  font-size: var(--font-size-lg);
  color: var(--green-basic);
  font-family: var(--font-inter);
}
.checklocationsection.animate {
  animation: 1s ease 0s 1 normal forwards slide-in-bottom;
}
.contactUs,
.text {
  position: relative;
  line-height: 2.93rem;
  font-weight: 600;
}
.contactUs {
  align-self: stretch;
  font-size: var(--font-size-17xl);
  font-family: var(--font-inter);
  color: var(--font-white-basic);
  text-align: left;
  opacity: 0;
}
.contactUs.animate {
  animation: 1s ease 0.15s 1 normal forwards slide-in-left;
}
.roomC64f,
.telcometrics1 {
  font-family: var(--font-heebo);
}
.roomC64f {
  margin: 0;
}
.telcometricsRoomC6Container1 {
  line-break: anywhere;
}
.telcometricsRoomC6Container {
  align-self: stretch;
  position: relative;
  font-size: var(--font-size-base);
  line-height: 1.5rem;
  color: var(--font-white-basic);
  text-align: left;
  display: flex;
  align-items: center;
  height: 4.5rem;
  flex-shrink: 0;
  opacity: 0;
}
.telcometricsRoomC6Container.animate {
  animation: 1s ease 0.25s 1 normal forwards slide-in-left;
}
.contactUsParent,
.email1,
.name {
  align-items: flex-start;
  justify-content: flex-start;
}
.contactUsParent {
  width: 16.25rem;
  display: flex;
  flex-direction: column;
  gap: 1.69rem;
}
.email1,
.name {
  font-family: var(--font-heebo);
  font-size: var(--font-size-base);
  flex: 1;
  border-radius: var(--br-3xs);
  box-shadow: 0 5px 15px -5px rgba(0, 0, 0, 0.11);
  overflow: hidden;
  padding: var(--padding-3xs) var(--padding-5xl);
  mix-blend-mode: screen;
  opacity: 0;
}
.name {
  border: 1px solid var(--green-basic);
  background-color: var(--font-white-basic);
  display: flex;
  flex-direction: row;
  color: #0EC9AC;
}
.name.animate {
  animation: 1s ease 0.15s 1 normal forwards slide-in-left;
}
.email1 {
  border: 0;
  background-color: var(--color-gray-500);
  color: #0EC9AC;
}
.email1.animate {
  animation: 1s ease 0.25s 1 normal forwards slide-in-right;
}
.email1,
.message,
.nameParent,
.submit1 {
  display: flex;
  flex-direction: row;
}
.nameParent {
  align-self: stretch;
  padding: 0 var(--padding-3xs) 0 0;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 2.06rem;
}
.message,
.submit1 {
  border: 0;
  border-radius: var(--br-3xs);
  box-sizing: border-box;
}
.message {
  background-color: var(--color-gray-500);
  font-family: var(--font-heebo);
  font-size: var(--font-size-base);
  align-self: stretch;
  box-shadow: 0 5px 15px -5px rgba(0, 0, 0, 0.11);
  height: 15.44rem;
  overflow: hidden;
  flex-shrink: 0;
  padding: var(--padding-3xs) var(--padding-5xl);
  align-items: flex-start;
  justify-content: flex-start;
  mix-blend-mode: screen;
  opacity: 0;
  z-index: 0;
  color: #0EC9AC;
}
.message.animate {
  animation: 1s ease 0.35s 1 normal forwards slide-in-bottom;
}
.submit1 {
  cursor: pointer;
  padding: var(--padding-mini) var(--padding-lg);
  background-color: var(--green-basic);
  width: 8.13rem;
  align-items: center;
  justify-content: center;
  transition: all 0.5s ease-out;
}
.submit1:hover {
  background-color: var(--color-turquoise);
  transform: scale(1.2, 1.2);
}
.submitWrapper {
  margin: 0 !important;
  position: absolute;
  top: 12.94rem;
  right: -0.75rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  opacity: 0;
  z-index: 1;
}
.submitWrapper.animate {
  animation: 1s ease 0.75s 1 normal forwards slide-in-right;
}
.shortMessage,
.shortMessage1,
.shortMessage2,
.shortMessage3 {
  position: relative;
  font-size: var(--font-size-base);
  font-family: var(--font-heebo);
  text-align: left;
}
.shortMessage {
  color: var(--green-basic);
}
.shortMessage1,
.shortMessage2,
.shortMessage3 {
  color: #500ec9;
}
.shortMessage2,
.shortMessage3 {
  color: #c90e2a;
}
.shortMessage3 {
  color: #88c90e;
}
.divplaceholder {
  align-self: stretch;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-3xs);
  z-index: 2;
}
.messageParent {
  align-self: stretch;
  flex-direction: column;
  padding: 0 var(--padding-3xs) var(--padding-mini) 0;
  align-items: flex-end;
  position: relative;
  gap: var(--gap-mini);
}
.frameParent10,
.frameParent11,
.messageParent {
  display: flex;
  justify-content: flex-start;
}
.frameParent11 {
  flex: 1;
  flex-direction: column;
  align-items: flex-end;
  gap: 1.31rem;
}
.frameParent10 {
  align-self: stretch;
  flex-direction: row;
  align-items: center;
  gap: var(--gap-81xl);
}
.submit3 {
  cursor: pointer;
  border: 0;
  padding: 0;
  background-color: var(--green-basic);
  position: relative;
  border-radius: var(--br-3xs);
  width: 8.16rem;
  height: 3.13rem;
  display: none;
}
.submit3:hover {
  background-color: var(--color-turquoise);
}
.divelementorWidgetWrapInner {
  align-self: stretch;
  background-color: var(--color-gray-300);
  display: flex;
  flex-direction: column;
  padding: 1.38rem 2.56rem;
  align-items: center;
  justify-content: flex-start;
  opacity: 0.95;
}
.divelementorBackgroundOverl5 {
  position: relative;
  border-radius: var(--br-3xs);
  background-color: var(--color-gray-300);
  width: 70rem;
  height: 23.56rem;
  display: none;
  opacity: 0.95;
}
.contactsection,
.divelementorWidgetWrap1 {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}
.divelementorWidgetWrap1 {
  border-radius: var(--br-3xs);
  background-image: url(/public/divelementorwidgetwrap@3x.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
  opacity: 0.96;
}
.contactsection {
  background-color: var(--color-gray-400);
  padding: var(--padding-71xl) var(--padding-11xl) var(--padding-31xl);
  box-sizing: border-box;
  gap: var(--gap-3xs);
  max-width: 75rem;
  text-align: left;
  font-size: var(--font-size-17xl);
  color: var(--font-white-basic);
  font-family: var(--font-inter);
}
.subscribeOurNewsletter {
  position: relative;
  line-height: 2.93rem;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 28.1rem;
  height: 2.75rem;
  flex-shrink: 0;
}
.loremIpsumDolorContainer1 {
  line-break: anywhere;
  width: 100%;
}
.loremIpsumDolorContainer {
  position: absolute;
  top: 0;
  left: 0;
  line-height: 1.5rem;
  display: flex;
  align-items: center;
  width: 35.4rem;
  height: 3rem;
}
.divelementorWidgetContainer1 {
  position: relative;
  width: 35.44rem;
  height: 3rem;
  font-size: var(--font-size-base);
  color: var(--color-lightslategray);
  font-family: var(--font-heebo);
}
.email2,
.submit5 {
  border: 0;
  position: absolute;
  border-radius: var(--br-3xs);
}
.email2 {
  font-family: var(--font-heebo);
  font-size: var(--font-size-base);
  background-color: var(--color-whitesmoke-100);
  width: calc(100% - 2px);
  top: 0.06rem;
  right: 0.06rem;
  left: 0.06rem;
  box-shadow: 0 5px 15px -5px rgba(0, 0, 0, 0.11);
  overflow: hidden;
  display: flex;
  flex-direction: row;
  padding: var(--padding-xl) var(--padding-13xl);
  box-sizing: border-box;
  align-items: flex-start;
  justify-content: flex-start;
}
.submit5 {
  cursor: pointer;
  padding: 0;
  background-color: var(--green-basic);
  top: 0.63rem;
  left: 22.16rem;
  width: 7.88rem;
  height: 3rem;
}
.divelementskitFormWraper {
  position: relative;
  width: 30.6rem;
  height: 4.13rem;
}
.subscribeOurNewsletterParent {
  background-color: rgba(1, 25, 54, 0.95);
  display: flex;
  flex-direction: column;
  padding: 4.81rem 17.25rem var(--padding-71xl);
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-7xl);
}
.divelementorWidgetWrap2 {
  border-radius: var(--br-3xs);
  display: flex;
  align-items: flex-start;
  background-image: url(/public/divelementorwidgetwrap1@3x.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
  opacity: 0.96;
}
.divelementorWidgetWrap2,
.needssectionParent,
.newslettersection {
  flex-direction: column;
  justify-content: flex-start;
}
.newslettersection {
  background-color: var(--color-gray-400);
  display: none;
  padding: 5.19rem 25rem 6.06rem;
  align-items: flex-start;
  gap: var(--gap-3xs);
  text-align: center;
  font-size: var(--font-size-17xl);
  color: var(--font-white-basic);
  font-family: var(--font-inter);
}
.needssectionParent {
  width: 75rem;
  display: flex;
  align-items: center;
  gap: 7.81rem;
  max-width: 75rem;
}
.divelementorContainer6 {
  position: absolute;
  top: 28.75rem;
  left: calc(50% - 960px);
  width: 120rem;
  height: 55.24rem;
}
.sectionelementorSection1,
.sectionelementorSection2,
.sectionelementorSection3 {
  position: relative;
  background-color: var(--color-gray-400);
  width: 120rem;
  height: 84rem;
  display: none;
}
.sectionelementorSection2,
.sectionelementorSection3 {
  height: 53.75rem;
}
.sectionelementorSection3 {
  height: 7.5rem;
}
.divelementorContainer7 {
  position: absolute;
  top: 32.25rem;
  left: calc(50% - 560px);
  width: 70rem;
  height: 20.7rem;
}
.divelementorWidgetWrap3 {
  position: absolute;
  width: calc(100% - 780px);
  top: 13.13rem;
  right: 24.38rem;
  left: 24.38rem;
  height: 53.57rem;
}
.sectionelementorSection4 {
  position: relative;
  width: 120rem;
  height: 72.94rem;
  background-image: url(/public/sectionelementorsection1@3x.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
  display: none;
}
.divelementorContainerParent {
  align-self: stretch;
  background: linear-gradient(
    180deg,
    rgba(0, 20, 44, 0.96) 46.88%,
    rgba(0, 20, 44, 0.98)
  );
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  gap: var(--gap-3xs);
}
.divelementor {
  align-self: stretch;
  padding: var(--padding-11xl) 0 0;
}
.divelementor,
.divpage,
.telcometricsreactweb02 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}
.divpage {
  align-self: stretch;
  gap: var(--gap-31xl);
}
.telcometricsreactweb02 {
  position: relative;
  background-color: var(--color-gray-400);
  width: 100%;
  gap: var(--gap-3xs);
}
@media screen and (max-width: 1200px) {
  .weAnalyseOurContainer {
    font-size: var(--font-size-13xl);
    line-height: 2.88rem;
    flex: 1;
  }
  .divjkitIconBoxWrapperContainer,
  .divjkitIconBoxWrapperWrapper {
    flex: 1;
    height: auto;
  }
  .frameGroup {
    flex: unset;
    height: auto;
    flex-direction: row;
    align-self: stretch;
  }
  .divjkitIconBoxWrapperFrame,
  .frameDiv {
    flex: 1;
    height: auto;
  }
  .frameParent {
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
  }
  .frameParent,
  .needssection {
    align-self: stretch;
    width: auto;
    height: auto;
  }
  .telecomSoftwareDevelopment {
    font-size: var(--font-size-17xl);
  }
  .divelementorBackgroundOverl1 {
    display: flex;
    align-self: stretch;
    width: auto;
    height: auto;
  }
  .frameParent5 {
    align-self: stretch;
    width: auto;
    height: auto;
    flex-direction: row;
  }
  .divelementorBackgroundOverl2 {
    display: none;
    flex: 1;
    height: auto;
  }
  .divelementorBackgroundOverl3 {
    display: flex;
    align-self: stretch;
    width: auto;
    height: auto;
  }
  .divelementorBackgroundOverl4 {
    display: none;
    flex: 1;
    height: auto;
  }
  .welcomesection {
    align-self: stretch;
    width: auto;
    height: auto;
  }
  .divjegElementorKitParent {
    flex-direction: row;
  }
  .counterssection {
    align-self: stretch;
    width: auto;
    height: auto;
  }
  .divelementorContainer3 {
    flex-direction: column;
  }
  .divelementorContainer3,
  .industriessection {
    align-self: stretch;
    width: auto;
    height: auto;
  }
  .divelementorContainer4 {
    align-self: stretch;
    width: auto;
    flex-direction: column;
  }
  .frameParent8,
  .offersection {
    align-self: stretch;
    width: auto;
    height: auto;
  }
  .divelementorContainer5 {
    align-self: stretch;
    width: auto;
    flex-direction: column;
  }
  .servicessection {
    align-self: stretch;
    width: auto;
    height: auto;
  }
  .divicon {
    display: none;
    height: auto;
  }
  .letsUseOur {
    text-align: center;
    flex: 1;
  }
  .divjkitIconBoxWrapper {
    height: auto;
  }
  .checkAbilityToContainer {
    font-size: var(--font-size-13xl);
    text-align: center;
    flex: 1;
    align-self: stretch;
    height: auto;
  }
  .h2elementorHeadingTitle {
    width: 31.25rem;
    height: auto;
  }
  .divelementorWidgetWrap {
    flex: unset;
    height: auto;
    align-items: center;
    justify-content: flex-start;
    align-self: stretch;
  }
  .email {
    align-self: stretch;
    width: auto;
  }
  .submit {
    display: none;
  }
  .frameWrapper {
    width: 25rem;
  }
  .checklocationsection,
  .divelementorWidgetWrapParent {
    align-self: stretch;
    width: auto;
    height: auto;
    flex-direction: column;
  }
  .nameParent {
    flex-direction: row;
  }
  .frameParent11 {
    flex: unset;
    align-self: stretch;
  }
  .frameParent10 {
    flex-direction: column;
  }
  .contactsection,
  .divelementorWidgetWrapInner,
  .frameParent10 {
    align-self: stretch;
    width: auto;
    height: auto;
  }
  .needssectionParent {
    flex: 1;
    height: auto;
  }
  .divelementor {
    align-self: stretch;
    width: auto;
  }
}
@media screen and (max-width: 960px) {
  .weAnalyseOurContainer {
    font-size: var(--font-size-xl);
    line-height: 2.25rem;
    flex: 1;
  }
  .divjkitIconBoxWrapperContainer,
  .divjkitIconBoxWrapperWrapper {
    flex: unset;
    height: auto;
    align-self: stretch;
  }
  .frameGroup {
    flex-direction: column;
  }
  .divjkitIconBoxWrapperFrame,
  .frameDiv,
  .frameGroup {
    flex: unset;
    height: auto;
    align-self: stretch;
  }
  .frameParent {
    height: auto;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
  }
  .frameParent,
  .needssection {
    align-self: stretch;
    width: auto;
  }
  .telecomSoftwareDevelopment {
    font-size: var(--font-size-17xl);
  }
  .divelementorBackgroundOverl1 {
    display: flex;
    align-self: stretch;
    width: auto;
    height: auto;
  }
  .frameParent5 {
    align-self: stretch;
    width: auto;
    height: auto;
    flex-direction: column;
  }
  .divelementorBackgroundOverl2 {
    display: none;
    flex: 1;
    height: auto;
  }
  .divelementorBackgroundOverl3 {
    display: flex;
    align-self: stretch;
    width: auto;
    height: auto;
  }
  .divelementorBackgroundOverl4 {
    display: none;
    flex: 1;
    height: auto;
  }
  .welcomesection {
    align-self: stretch;
    width: auto;
  }
  .divjegElementorKitParent {
    flex-direction: column;
  }
  .counterssection {
    align-self: stretch;
    width: auto;
  }
  .divelementorContainer3 {
    height: auto;
    flex-direction: column;
  }
  .divelementorContainer3,
  .industriessection {
    align-self: stretch;
    width: auto;
  }
  .divelementorContainer4 {
    flex-direction: column;
  }
  .divelementorContainer4,
  .frameParent8,
  .offersection {
    align-self: stretch;
    width: auto;
  }
  .frameParent8 {
    height: auto;
  }
  .divelementorContainer5,
  .frameParent8 {
    flex-direction: column;
  }
  .divelementorContainer5,
  .servicessection {
    align-self: stretch;
    width: auto;
  }
  .divicon {
    display: none;
    height: auto;
  }
  .letsUseOur {
    text-align: center;
    flex: 1;
  }
  .divjkitIconBoxWrapper {
    align-self: stretch;
    width: auto;
    height: auto;
  }
  .checkAbilityToContainer {
    font-size: var(--font-size-5xl);
    flex: 1;
    align-self: stretch;
    height: auto;
  }
  .h2elementorHeadingTitle {
    align-self: stretch;
    width: auto;
    height: auto;
  }
  .divelementorWidgetWrap {
    flex: 1;
    height: auto;
  }
  .email {
    align-self: stretch;
    width: auto;
  }
  .submit {
    display: flex;
  }
  .frameWrapper {
    flex: 1;
  }
  .divelementorWidgetWrapParent {
    align-self: stretch;
    width: auto;
    height: auto;
  }
  .checklocationsection {
    align-self: stretch;
    width: auto;
    flex-direction: column;
  }
  .email1,
  .name {
    flex: unset;
    align-self: stretch;
  }
  .nameParent {
    flex-direction: column;
  }
  .frameParent11 {
    flex: unset;
    align-self: stretch;
  }
  .frameParent10 {
    flex-direction: column;
  }
  .contactsection,
  .divelementorWidgetWrapInner,
  .frameParent10 {
    align-self: stretch;
    width: auto;
    height: auto;
  }
  .newslettersection {
    display: none;
  }
  .needssectionParent {
    flex: 1;
    height: auto;
  }
}
@media screen and (max-width: 420px) {
  .weAnalyseOurContainer {
    font-size: var(--font-size-xl);
    line-height: 2.25rem;
    flex: 1;
  }
  .divjkitIconBoxWrapperContainer,
  .divjkitIconBoxWrapperWrapper {
    flex: unset;
    height: auto;
    align-self: stretch;
  }
  .frameGroup {
    flex-direction: column;
  }
  .divjkitIconBoxWrapperFrame,
  .frameDiv,
  .frameGroup {
    flex: unset;
    height: auto;
    align-self: stretch;
  }
  .frameParent {
    height: auto;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
  }
  .frameParent,
  .needssection {
    align-self: stretch;
    width: auto;
  }
  .telecomSoftwareDevelopment {
    font-size: var(--font-size-17xl);
  }
  .divelementorBackgroundOverl1 {
    display: flex;
    align-self: stretch;
    width: auto;
    height: auto;
  }
  .frameParent5 {
    align-self: stretch;
    width: auto;
    height: auto;
    flex-direction: column;
  }
  .divelementorBackgroundOverl2 {
    display: none;
    flex: 1;
    height: auto;
  }
  .divelementorBackgroundOverl3 {
    display: flex;
    align-self: stretch;
    width: auto;
    height: auto;
  }
  .divelementorBackgroundOverl4 {
    display: none;
    flex: 1;
    height: auto;
  }
  .welcomesection {
    align-self: stretch;
    width: auto;
  }
  .divjegElementorKitParent {
    flex-direction: column;
  }
  .counterssection {
    align-self: stretch;
    width: auto;
  }
  .divelementorContainer3 {
    height: auto;
    flex-direction: column;
  }
  .industriessection {
    align-self: stretch;
    width: auto;
  }
  .divelementorContainer4 {
    flex-direction: column;
  }
  .frameParent8,
  .offersection {
    align-self: stretch;
    width: auto;
  }
  .offersection {
    align-self: stretch;
    max-width: 360px;
    margin-left: auto;
    margin-right: auto;
    overflow-x: hidden;
  }
  .frameParent8 {
    height: auto;
  }
  .divelementorContainer5,
  .frameParent8 {
    flex-direction: column;
  }
  .servicessection {
    align-self: stretch;
    width: auto;
  }
  .divicon {
    display: none;
    height: auto;
  }
  .letsUseOur {
    text-align: center;
    flex: 1;
  }
  .divjkitIconBoxWrapper {
    align-self: stretch;
    width: auto;
  }
  .checkAbilityToContainer {
    font-size: var(--font-size-5xl);
    flex: 1;
    align-self: stretch;
    height: auto;
  }
  .h2elementorHeadingTitle {
    align-self: stretch;
    width: auto;
    height: auto;
  }
  .divelementorWidgetWrap {
    flex: unset;
    height: auto;
    align-self: stretch;
  }
  .email {
    align-self: stretch;
    width: auto;
  }
  .submit {
    display: flex;
  }
  .frameWrapper {
    flex: 1;
  }
  .divelementorWidgetWrapParent {
    height: auto;
  }
  .checklocationsection,
  .divelementorWidgetWrapParent {
    align-self: stretch;
    width: auto;
    flex-direction: column;
  }
  .email1,
  .name {
    flex: unset;
    align-self: stretch;
  }
  .nameParent {
    flex-direction: column;
  }
  .frameParent11 {
    flex: unset;
    align-self: stretch;
  }
  .frameParent10 {
    flex-direction: column;
  }
  .contactsection,
  .divelementorWidgetWrapInner,
  .frameParent10 {
    align-self: stretch;
    width: auto;
    height: auto;
  }
  .contactsection {
    align-self: stretch;
    max-width: 360px;
    margin-left: auto;
    margin-right: auto;
    overflow-x: hidden;
  }
  .newslettersection {
    display: none;
  }
  .needssectionParent {
    flex: 1;
    height: auto;
  }
}
