.icon,
.weAssess {
  position: relative;
}
.icon {
  width: 2.34rem;
  height: 1.64rem;
}
.weAssess {
  align-self: stretch;
  line-height: 1.75rem;
  font-weight: 600;
  display: flex;
  align-items: center;
  height: 2.38rem;
  flex-shrink: 0;
}
.loremIpsumDolor {
  margin: 0;
}
.loremIpsumDolorContainer {
  flex: 1;
  position: relative;
  line-height: 1.5rem;
}
.diviconBox,
.divjkitIconBoxWrapper4,
.piconBoxDescription {
  align-self: stretch;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.piconBoxDescription {
  flex-direction: row;
  padding: var(--padding-12xs) 0;
  font-size: var(--font-size-base);
  color: var(--color-lightslategray);
  font-family: var(--font-heebo);
}
.diviconBox,
.divjkitIconBoxWrapper4 {
  flex-direction: column;
  gap: var(--gap-2xs);
}
.divjkitIconBoxWrapper4 {
  border-radius: var(--br-3xs);
  background-color: var(--color-gray-300);
  border: 1px solid var(--color-mediumaquamarine-200);
  padding: var(--padding-19xl) var(--padding-2xl) var(--padding-16xl);
  gap: var(--gap-9xl);
  transition: all 0.5s ease-out;
  text-align: left;
  font-size: var(--font-size-xl);
  color: var(--color-whitesmoke-200);
  font-family: var(--font-inter);
}
.divjkitIconBoxWrapper4:hover {
  background-color: var(--color-gray-300);
  border: 1px solid var(--green-basic);
  box-sizing: border-box;
  transform: scale(1.1, 1.1);
}
@media screen and (max-width: 1200px) {
  .divjkitIconBoxWrapper4 {
    align-self: stretch;
    width: auto;
    height: auto;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .divjkitIconBoxWrapper4:hover {
    opacity: 1;
  }
}
@media screen and (max-width: 960px) {
  .divjkitIconBoxWrapper4 {
    align-self: stretch;
    width: auto;
    height: auto;
    align-items: center;
    justify-content: flex-start;
    opacity: 1;
  }
  .divjkitIconBoxWrapper4.animate {
    animation: 1s ease 0s 1 normal forwards scale-up;
  }
  @keyframes scale-up {
    0% {
      transform: scale(0.5);
    }
    to {
      transform: scale(1);
    }
  }
}
@media screen and (max-width: 420px) {
  .divjkitIconBoxWrapper4 {
    align-self: stretch;
    width: auto;
    height: auto;
    align-items: center;
    justify-content: flex-start;
  }
  .divjkitIconBoxWrapper4:hover {
    transform: scale(1.0, 1.0);
  }
  .loremIpsumDolor {
    margin: 0;
    height: 120px;
  }
}
