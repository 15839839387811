@keyframes fade-in {
  0% {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes scale-down-center {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  to {
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
  }
}
.vectorIcon {
  position: relative;
  width: auto;
  height: 2.95rem;
  object-fit: fit;
}
.component1 {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  padding: var(--padding-12xs) 0;
  align-items: flex-start;
  justify-content: flex-start;
}
.publicSector {
  margin: 0;
}
.publicSectorOrganisationsContainer {
  position: relative;
  font-weight: 600;
}
.component1Parent,
.industriesbox5 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}
.component1Parent {
  align-self: stretch;
  gap: var(--gap-xs);
  opacity: 0;
}
.component1Parent.animate {
  animation: 1s ease 0.75s 1 normal forwards fade-in;
}
.industriesbox5 {
  flex: 1;
  opacity: 1;
  text-align: center;
  font-size: var(--font-size-5xl);
  color: var(--font-white-basic);
  font-family: var(--font-inter);
}
.industriesbox5.animate {
  animation: 1s ease 0.75s 1 reverse forwards scale-down-center;
}
@media screen and (max-width: 1200px) {
  .industriesbox5 {
    flex: unset;
    align-self: stretch;
    margin-bottom: 50px;
  }
}
@media screen and (max-width: 960px) {
  .industriesbox5 {
    flex: unset;
    align-self: stretch;
    margin-bottom: 50px;
  }
}
@media screen and (max-width: 420px) {
  .industriesbox5 {
    flex: unset;
    align-self: stretch;
    margin-bottom: 50px;
  }
}
