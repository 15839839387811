@keyframes slide-in-left {
  0% {
    transform: translateX(-200px);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}
@keyframes slide-in-right {
  0% {
    transform: translateX(200px);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}
.privacyPolicy,
.termsCondition {
  position: relative;
  line-height: 1.14rem;
  font-weight: 500;
  opacity: 0;
}
.privacyPolicy.animate {
  animation: 1s ease 0.15s 1 normal forwards slide-in-left;
}
.termsCondition.animate {
  animation: 1s ease 0.25s 1 normal forwards slide-in-left;
}
.ulelementorIconListItems {
  display: flex;
  flex-direction: row;
  padding: 0 var(--padding-xl);
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-21xl);
}
.divelementorWidgetContainer {
  align-self: stretch;
  flex: 1;
  overflow: hidden;
  display: none;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.copyright2023 {
  flex: 1;
  position: relative;
  line-height: 1.14rem;
  font-weight: 500;
  opacity: 0;
}
.copyright2023.animate {
  animation: 1s ease 0.15s 1 normal forwards slide-in-right;
}
.copyright2023AllRightsRWrapper,
.divelementorContainer {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}
.copyright2023AllRightsRWrapper {
  flex: 1;
  justify-content: flex-end;
  text-align: center;
}
.divelementorContainer {
  justify-content: flex-start;
  gap: var(--gap-6xl);
}
.sectionelementorSection1 {
  align-self: stretch;
  border-top: 1px solid var(--color-gray-200);
  padding: var(--padding-6xl) 0 0;
  align-items: flex-start;
}
.divelementorWidgetWrap,
.sectionelementorSection,
.sectionelementorSection1 {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.divelementorWidgetWrap {
  width: 75rem;
  padding: 0 var(--padding-11xl);
  box-sizing: border-box;
  align-items: flex-start;
  max-width: 75rem;
}
.sectionelementorSection {
  align-self: stretch;
  background-color: var(--color-gray-400);
  padding: var(--padding-81xl) 0 var(--padding-21xl);
  align-items: center;
  text-align: left;
  font-size: var(--font-size-sm);
  color: var(--font-white-basic);
  font-family: var(--font-inter);
}
@media screen and (max-width: 1200px) {
  .divelementorWidgetContainer {
    flex: 1;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .copyright2023 {
    text-align: center;
    flex: 1;
  }
  .copyright2023AllRightsRWrapper {
    flex: 1;
  }
  .divelementorContainer {
    flex-direction: row;
  }
  .divelementorWidgetWrap {
    align-self: stretch;
    width: auto;
    height: auto;
  }
}
@media screen and (max-width: 960px) {
  .divelementorWidgetContainer {
    flex: unset;
    align-items: center;
    justify-content: flex-start;
    align-self: stretch;
  }
  .copyright2023 {
    text-align: center;
  }
  .copyright2023AllRightsRWrapper {
    flex: unset;
    align-self: stretch;
  }
  .divelementorContainer {
    flex-direction: column;
  }
  .divelementorWidgetWrap {
    align-self: stretch;
    width: auto;
    flex: 1;
  }
}
@media screen and (max-width: 420px) {
  .divelementorWidgetContainer {
    flex: unset;
    align-items: center;
    justify-content: flex-start;
    align-self: stretch;
  }
  .copyright2023 {
    text-align: center;
  }
  .copyright2023AllRightsRWrapper {
    flex: unset;
    align-self: stretch;
  }
  .divelementorContainer {
    flex-direction: column;
  }
  .divelementorWidgetWrap {
    align-self: stretch;
    width: auto;
    height: auto;
  }
}
