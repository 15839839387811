@keyframes slide-in-left {
  0% {
    transform: translateX(-200px);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}
@keyframes slide-in-bottom {
  0% {
    transform: translateY(100px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}
.seminars {
  align-self: stretch;
  position: relative;
  line-height: 2.93rem;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 2.75rem;
  flex-shrink: 0;
}
.frameChild {
  position: relative;
  width: 6.38rem;
  height: 0.13rem;
}
.ourExperiencedProfessionals,
.seminarsParent {
  display: flex;
  align-items: center;
  justify-content: center;
}
.ourExperiencedProfessionals {
  align-self: stretch;
  position: relative;
  font-size: var(--font-size-base);
  line-height: 1.5rem;
  font-family: var(--font-heebo);
  color: var(--color-lightslategray);
  height: 4.5rem;
  flex-shrink: 0;
}
.seminarsParent {
  flex: 1;
  flex-direction: column;
  gap: var(--gap-5xl);
  opacity: 0;
  text-align: center;
  font-size: var(--font-size-17xl);
  color: var(--font-white-basic);
  font-family: var(--font-inter);
}
.seminarsParent.animate {
  animation: 1s ease 0.15s 1 normal forwards slide-in-bottom;
}
@media screen and (max-width: 960px) {
  .seminarsParent {
    flex: unset;
    align-self: stretch;
  }
}
@media screen and (max-width: 420px) {
  .seminarsParent {
    flex: unset;
    align-self: stretch;
  }
}
